@media screen and (max-width: 576px) {
  body {
    padding: 0px;
    background: transparent !important;
    background-color: transparent !important;
    --ion-background-color: transparent !important;
    --background: transparent !important;
  }
  .dimond-coin {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px !important;
    margin-right: 20px !important;
    img {
      width: 22px !important;
    }
  }
  .wheel-page {
    height: 40%;
    top: 18px;

    .roulette-wheel {
      left: 0;
      margin-left: 0px;
      width: 250px;
      height: 250px;
      background-size: 250px 250px;
      svg {
        width: 250px !important;
        height: 250px !important;
        circle {
          cx: 125px;
          cy: 125px;
          r: 125px;
        }
      }
      .layer-2,
      .layer-3,
      .layer-4,
      .layer-5,
      .ball-container {
        background-size: 250px 250px;
      }
      .ball {
        width: 11px;
        height: 11px;
        transform: translate(0px, -128.221px) !important;
        margin: -6px;
      }
    }
  }
  .game-main {
    top: 37%;
    .table-center-data {
      margin: 10px 19px !important;
      .history-icon {
        display: none !important;
      }
    }
  }
  .coinShow {
    width: 30px !important;
    height: 30px !important;
    .cardShowCoin_ {
      font-size: 10px;
    }
  }
  .dialog .mainDiaogBox {
    min-width: 366px;
    .dialog-data {
      max-height: 199px;
      .dialog-head {
        img {
          width: 123px;
          margin-top: -64px;
        }
      }
      .dialog-body {
        h6 {
          margin-top: 16px;
        }
        .chipNumberShow {
          width: 63px;
          img {
            width: 63px;
          }
          span {
            font-size: 16px;
          }
        }
        h6 {
          font-size: 14px;
        }
      }
      .show-closeData {
        width: 22px;
        height: 22px;
        font-size: 13px;
      }
    }
  }
  .top-btn-show {
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin-left: 19px !important;
  }
  .helpIcon-button {
    button {
      width: 40px !important;
      height: 40px !important;
      svg {
        width: 20px !important;
      }
    }
  }
  .sound-icon {
    margin-left: 0px !important;
    button {
      width: 40px !important;
      height: 40px !important;
      svg {
        width: 20px !important;
      }
    }
  }
  .history-icon {
    margin: 8px 0px !important;
    button {
      width: 40px !important;
      height: 40px !important;
      i {
        width: 17px !important;
      }
    }
  }
  .user-button {
    margin-right: 15px !important;
    button {
      width: 40px !important;
      height: 40px !important;
      svg {
        width: 20px !important;
        height: 26px !important;
      }
    }
  }
  .coin-button-box {
    .coinBtn {
      .cardShowCoin_ {
        width: 45px !important;
        height: 45px !important;
        font-size: 13px !important;
        font-weight: bolder !important;
      }
    }
  }
  .table-timeWatch {
    .time-watch {
      width: 88px !important;
      height: 37px !important;
      margin-top: 1px;
      &::after {
        bottom: -10px !important;
        width: 44% !important;
        height: 7px !important;
        border-radius: 2px 2px 6px 6px !important;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .table-headText {
    h6 {
      font-size: 14px !important;
    }
  }
  .table-oldBet {
    width: 53px !important;
    h6 {
      font-size: 12px !important;
    }
    .old-bet-boxNumber {
      width: 25px !important;
      height: 25px !important;
      span {
        font-size: 12px;
      }
    }
  }
  .rules-model {
    .rulesModelBox {
      background-size: 300px 300px;
      width: 300px;
      height: 300px;
      .model-head {
        h6 {
          padding-top: 7px;
          font-size: 17px;
        }
        .close-icon {
          top: 4px;
          svg {
            width: 25px;
            height: 25px;
          }
        }
      }
      .model-body {
        max-height: 238px;
        margin-top: 20px;
        h4 {
          font-size: 16px;
        }
        ol {
          padding-left: 24px;
          li {
            font-size: 13px;
          }
        }
        ul {
          padding-left: 24px;
          li {
          }
        }
        table {
          thead {
            th {
              font-size: 14px;
              padding-bottom: 3px;
            }
          }
          tbody {
            td {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .winnerModel .winnerModel-bg {
    height: 250px;
    background-size: 100% 250px;
    .model {
      .model-head {
        img {
          margin-top: -83px;
          width: 150px;
          height: 150px;
        }
        .time-count {
          width: 40px;
          height: 22px;
          font-size: 14px;
        }
      }
      .model-body {
        .winner-fram {
          width: 150px;
          height: 40px;
          h6 {
            font-size: 15px;
            span {
              font-size: 19px;
            }
          }
        }
        h5 {
          margin-top: 20px;
          font-size: 17px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .history-model {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px !important;
    .historyModelBox {
      background: url("../assets/RulesModel.png") !important;
      background-size: 275px 280px !important;
      background-repeat: no-repeat !important;
      width: 275px !important;
      height: 280px !important;
    }
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 440px) {
  .Toastify {
    .Toastify__toast-container {
      width: 300px;
      height: 7px;
      right: 0;
      left: unset;
      top: 7px;
    }
  }

  body {
    background: transparent !important;
    background-color: transparent !important;
    --ion-background-color: transparent !important;
    --background: transparent !important;
  }
  .selectedCoin {
    box-shadow: 1px 1px 0 #bbb, 0 0 6px 3px #f0f0f0, inset 1px 1px 0 #fff !important;
  }
  .coinBox {
    .coinShow {
      height: 20px !important;
      width: 20px !important;
      span {
        font-size: 5px !important;
      }
      .cardShowCoin_ {
        font-size: 5px !important;
      }
    }
  }
  .coinShow {
    height: 20px !important;
    width: 20px !important;
  }
  .coinBoxMine {
    .coinShow {
      height: 20px !important;
      width: 20px !important;
      span {
        font-size: 5px !important;
      }
      .cardShowCoin_ {
        font-size: 5px !important;
      }
    }
  }
  .game-page {
    .game-show {
      border: none;
      .top-btn-show {
        display: none;
      }
      .dimond-coin {
        display: none;
      }
      .helpIcon-button {
        button {
          width: 30px !important;
          height: 30px !important;
        }
      }
      .history-icon {
        margin: 4px 0 !important;
        button {
          width: 30px !important;
          height: 30px !important;
          i {
            font-size: 16px !important;
          }
        }
      }
      .sound-icon {
        button {
          width: 30px !important;
          height: 30px !important;
        }
      }
      .dimond-coin {
        border-radius: 6px;
        padding: 5px 6px;
        img {
          width: 14px !important;
          height: 14px !important;
        }
        span {
          margin-left: 4px;
          font-size: 10px;
        }
      }
      .wheel-page {
        top: 4% !important;
        z-index: 11;
        .roulette-wheel {
          left: 0;
          margin-left: 0px;
          width: 190px;
          height: 190px;
          background-size: 190px 190px;
          svg {
            width: 190px !important;
            height: 190px !important;
            circle {
              cx: 95px;
              cy: 95px;
              r: 95px;
            }
          }
          .layer-2,
          .layer-3,
          .layer-4,
          .layer-5,
          .ball-container {
            background-size: 190px 190px;
          }
          .ball {
            height: 6px;
            margin: -3px;
            transform: translateY(-109.221px) !important;
            width: 6px;
          }
        }
      }
      .game-main {
        top: 22%;
        .table-content {
          .sound-icon-mo {
            margin-left: 5px !important;
            button {
              width: 30px !important;
              height: 30px !important;
              background: rgb(84 158 175 / 12%);
              border-radius: 50%;
              box-shadow: inset 0px 1px 4px 1px rgb(51 129 163 / 64%);
              backdrop-filter: blur(0.4px);
              -webkit-backdrop-filter: blur(0.4px);
              border: 0.1px solid rgb(255 255 255 / 13%);
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                font-size: 20px;
                fill: rgba(255, 229, 12, 0.7490196078);
                width: 20px !important;
                height: 100%;
                g {
                  path {
                    fill: rgba(255, 229, 12, 0.7490196078);
                  }
                }
              }
            }
          }
          .table-data {
            .table-center-data {
              margin-top: 28vh !important;
            }
            .show-button-top{
              display: flex;
            }
            .helpIcon-button {
              margin-left: 8px;
              display: flex;
            }
            .table-userButton {
              margin-top: 0vh;
              margin-top: 0px;
              position: absolute;
              bottom: 88%;
              z-index: 99999;
              transform: translate(0%, 80%) !important;
              left: 7%;
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: flex-start;
              .user-button {
                margin-top: 0px;
                margin-right: 35px !important;
                button {
                  width: 30px !important;
                  height: 30px !important;
                }
              }
            }
            .table-head {
              margin-top: -35px;
              margin-bottom: 12px;
              .table-oldBet {
                width: 40px !important;
                h6 {
                  font-size: 9px !important;
                  margin-bottom: 3px;
                  padding: 1px;
                  border-top-left-radius: 3px;
                  border-top-right-radius: 3px;
                }
                .old-bet-showNumber {
                  margin-bottom: 3px;
                  .old-bet-boxNumber {
                    height: 20px !important;
                    padding: 3px;
                    width: 20px !important;
                    span {
                      font-size: 9px;
                    }
                  }
                }
              }
              .table-headText {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                h6 {
                  font-size: 10px !important;
                  margin-bottom: 2px;
                }
              }
              .table-timeWatch {
                position: relative;
                .time-watch {
                  height: 34px !important;
                  margin-top: 1px;
                  width: 62px !important;
                  border-radius: 12px;
                  &::after {
                    border-radius: 1px 1px 3px 3px !important;
                    bottom: -7px !important;
                    height: 6px !important;
                    width: 41% !important;
                  }
                  span {
                    font-size: 9px;
                  }
                }
              }
            }
            .table-body {
              margin-top: 7px;
              .table-box {
                gap: 4px;
                .bet-details {
                  height: 15vh;
                  max-height: 75px;
                  .bet-box {
                    border: 1px solid #3a3;
                    border-radius: 4px;
                    height: 100%;
                    .bet-top {
                      h6 {
                        font-size: 11px;
                        margin-bottom: 4%;
                        padding: 2px 0;
                      }
                    }
                    .bet-number {
                      h4 {
                        font-size: 15px;
                        margin-bottom: 0px;
                      }
                      h5 {
                        font-size: 15px;
                        margin-bottom: 0px;
                      }
                      .coinBox {
                        .coinShow {
                          height: 20px !important;
                          width: 20px !important;
                          span {
                            font-size: 5px !important;
                          }
                          .cardShowCoin_ {
                            font-size: 5px !important;
                          }
                        }
                      }
                      .coinBoxMine {
                        .coinShow {
                          height: 20px !important;
                          width: 20px !important;
                          span {
                            font-size: 5px !important;
                          }
                          .cardShowCoin_ {
                            font-size: 5px !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .table-footer {
              bottom: 2% !important;
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: space-evenly;
              flex-direction: row;
              .dimond-coin {
                margin-left: 10px !important;
                display: block !important;
                position: relative !important;
                margin: 0px !important;
                top: 0px !important;
                right: unset !important;
                width: 100%;
                padding: 2px 3px !important;
                margin-right: 0px !important;
                max-width: 70px;
                margin-left: 3px !important;
                overflow: scroll;
                img {
                  width: 16px !important;
                  margin-top: -3px;
                  height: 16px !important;
                }
                span {
                  height: 10px !important;
                  margin-top: -3px;
                  width: 10px !important;
                }
              }
              .history-icon {
                display: block !important;
                position: relative !important;
                margin-right: 10px !important;
              }
              .coin-button-box {
                width: unset !important;
                .coinBtn {
                  button {
                    font-size: 7px !important;
                    height: 30px !important;
                    width: 30px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .rules-model .model-body::-webkit-scrollbar {
    width: 3px !important;
  }
  .rules-model {
    height: 100%;
    z-index: 99999999;
    .rulesModelBox {
      background-size: 250px 250px;
      height: 250px;
      width: 250px;
      .model-head {
        h6 {
          font-size: 13px;
          padding-top: 9px;
        }
        .close-icon {
          top: 1px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .model-body {
        margin-top: 15px;
        max-height: 200px;

        ::-webkit-scrollbar {
          width: 2px !important;
        }
        p {
          color: #d79855;
          font-size: 12px;
          margin-bottom: 2px;
          margin-top: 2px;
        }
        ol {
          margin-bottom: 10px;
          padding-left: 17px !important;
          margin-bottom: 6px;
          li {
            font-size: 9px !important;
          }
        }
        ul {
          padding-left: 13px !important;
          li {
            font-size: 10px !important;
            span {
              font-size: 10px !important;
            }
          }
        }
        table {
          thead {
            tr {
              th {
                font-size: 11px;
                padding-bottom: 1px;
              }
            }
          }
          tbody {
            tr {
              td {
                &:nth-child(1) {
                  border-top-left-radius: 8px;
                  border-bottom-left-radius: 8px;
                }
                &:nth-last-child(1) {
                  border-top-right-radius: 8px;
                  border-bottom-right-radius: 8px;
                }
                font-size: 10px;
                padding: 2px 0;
                .winner-Box {
                  .winner-number {
                    height: 16px;
                    width: 16px;
                    font-size: 8px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .winnerModel {
    .winnerModel-bg {
      height: 185px;
      background-size: 100% 185px;
      bottom: 17vh;
      .model {
        .model-head {
          img {
            margin-top: -62px;
            width: 100px;
            height: 100px;
          }
          .time-count {
            padding: 8px;
            width: 36px;
            height: 20px;
            font-size: 12px;
          }
        }
        .model-body {
          .winner-fram {
            height: 30px;
            margin-top: 10px;
            width: 80px;
            h6 {
              font-size: 10px;
              span {
                font-size: 10px;
                margin-left: 4px;
                margin-top: 1px;
              }
            }
          }
          h5 {
            font-size: 10px !important;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .history-model {
    height: 100%;
    bottom: 50%;
    left: 10%;
    transform: translate(0%, 8%);
    .historyModelBox {
      background-size: 250px 130px !important;
      height: 130px !important;
      width: 250px !important;
      .model-head {
        h6 {
          font-size: 10px;
          padding-top: 3px;
        }
        .close-icon {
          top: -7px;
          right: -3px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
      .model-body {
        padding: 1px 5px;
        margin-top: 8px;
        h5 {
          font-size: 12px;
          margin-top: 40px;
          text-align: center;
          width: 100%;
        }
        .round-number {
          width: 20px;
          height: 20px;
          h6 {
            font-size: 10px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
